<template>
  <div class="my-money">
    <div class="container-xl">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 p-0">
          <div class="card h-100">
            <div class="card-header">
              <div class="card-title">Остаток на счете</div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-7 small text-muted">Доступно</div>
                <div class="col-5">
                  <money :money="myMoney.balance" :decimal="true"/>
                </div>
              </div>
              <div class="row">
                <div class="col-7 small text-muted">На удержании</div>
                <div class="col-5">
                  <money :money="myMoney.reservedFunds" :decimal="true"/>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <router-link class="btn btn-sm btn-success w-45 float-left" :to="{ name: 'deposit' }">Пополнить</router-link>
              <router-link class="btn btn-sm btn-info w-45 float-right" :to="{ name: 'withdraw' }">Вывести</router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 p-0 pt-3 p-md-0">
          <div class="card h-100">
            <div class="card-header">
              <div class="card-title">Портфель инвестиций</div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-7 small text-muted">Портфель</div>
                <div class="col-5">
                  <money :money="myMoney.investedSum || undefined" :decimal="true"/>
                </div>
              </div>
              <div class="row">
                <div class="col-7 small text-muted">Полученный доход</div>
                <div class="col-5">
                  <money :money="myMoney.earned" :decimal="true"/>
                </div>
              </div>
              <div class="row">
                <div class="col-7 small text-muted">Средняя ставка</div>
                <div class="col-5">
                  {{ myMoney.averageInterestRate ? `${myMoney.averageInterestRate.toFixed(1)}%` : '-' }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 p-0 pt-3 p-lg-0">
          <div class="card h-100">
            <div class="card-header">
              <div class="card-title">Движение средств</div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-7 small text-muted">Всего зачислено средств</div>
                <div class="col-5">
                  <money :money="myMoney.totalDeposits" :decimal="true"/>
                </div>
              </div>
              <div class="row">
                <div class="col-7 small text-muted">Всего выведено средств</div>
                <div class="col-5">
                  <money :money="myMoney.totalWithdraws" :decimal="true"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-8 col-md-12 p-0">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Ближайшие выплаты</div>
            </div>
            <div class="card-body pb-3">
              <div class="row">
                <div class="col-md-5 col-sm-12">
                  <calendar :selected-dates="myMoney.repaymentDates || []"
                            @input="calendarInput"/>
                </div>
                <div class="col-md-7 col-sm-12 mt-3 mt-md-0">
                  <div v-if="dayRepaymentsLoading"><i>Загрузка...</i></div>
                  <div v-if="!dayRepaymentsLoading && dayRepayments.length === 0">Нет выплат</div>
                  <div class="overflow-auto" style="max-height: 450px">
                    <div class="card m-2" v-for="item in dayRepayments">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-10">
                            <div class="h6">{{ item.project.name }}</div>
                            <div class="text-muted small">ID {{ item.project.paymentCode }}</div>
                          </div>
                          <div class="col-2">
                            <div class="text-success">{{ item.mortgageRank || "-" }}</div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-7 small text-muted">Инвестиция</div>
                          <div class="col-5">
                            <money :money="item.investedMoney" :decimal="true"/>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-7 small text-muted">Ставка</div>
                          <div class="col-5">
                            {{ item.project.interestRate }}%
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-7 small text-muted">Месяц</div>
                          <div class="col-5">
                            {{ item.repayment.month }}/{{ item.project.initialTerm }}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-7 small text-muted">Следующий платеж</div>
                          <div class="col-5">
                            <div class="small text-muted">
                              <date-format :date="item.repayment.date"/>
                            </div>
                            <div class="h6">
                              <money :money="calculateTotalPayment(item.repayment)" :decimal="true"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Calendar, DateFormat, Money } from "@/components/common";
import { createDayRepaymentProvider, createMyMoneyProvider } from "@/utils/di";
import moment from "moment";

export default {
  name: "MyMoney",
  components: {
    Calendar,
    DateFormat,
    Money,
  },
  inject: ["profile"],
  async created() {
    this.cachedDayRepayments = {};

    const provider = await createMyMoneyProvider();
    const { repaymentDates, ...myMoney } = await provider.get();
    this.myMoney = myMoney;
    this.myMoney.repaymentDates = repaymentDates.map(date => new Date(date));

    await this.calendarInput(new Date());
  },
  data() {
    return {
      myMoney: {},
      dayRepayments: [],
      dayRepaymentsLoading: false,
    };
  },
  methods: {
    async calendarInput(date) {
      if (date) {
        const mDate = moment(date);
        const cacheKey = mDate.format("YYYY-MM-DD");
        this.dayRepayments = [];
        if (this.myMoney.repaymentDates.some(item => mDate.isSame(item, "day"))) {
          if (this.cachedDayRepayments[cacheKey]) {
            this.dayRepayments = this.cachedDayRepayments[cacheKey];
          } else {
            this.dayRepaymentsLoading = true;
            try {
              const provider = await createDayRepaymentProvider();
              this.dayRepayments = this.cachedDayRepayments[cacheKey] = await provider.getByDate(date);
            } finally {
              this.dayRepaymentsLoading = false;
            }
          }
        }
      }
    },
    calculateTotalPayment(repayment) {
      let totalPaymentAmount = repayment.principal.amount + repayment.interest.amount;
      if (repayment.penalty) {
        totalPaymentAmount += repayment.penalty.amount;
      }
      return { amount: totalPaymentAmount, currencyCode: repayment.principal.currencyCode };
    }
  }
};
</script>

<style lang="scss">
.my-money {
  .w-45 {
    width: 45%;
  }

  .card {
    margin: 1rem;

    .card-header {
      .card-title {
        font-size: 1rem;
      }
    }

    .card-body {
      padding-top: 0;
      padding-bottom: 0;
    }

    .card-footer {
      padding-top: 0;
    }
  }
}
</style>
